define("ember-tracked-local-storage/services/tracked-local-storage", ["exports", "@ember/service", "ember-tracked-local-storage/utils/tracked-local-storage", "macro-decorators", "@ember/utils"], function (_exports, _service, _trackedLocalStorage, _macroDecorators, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * TrackedLocalStorage Service designed to create an instance of the base TrackedLocalStorage class
   * for ember lifecycle management and provide shorthand access to the API.
   *
   * Also implements some extra features regarding prefixing values and validation messages.
   */
  let TrackedLocalStorageService = _exports.default = (_dec = (0, _macroDecorators.reads)('_trackedLocalStorage.length'), (_class = class TrackedLocalStorageService extends _service.default {
    constructor() {
      super(...arguments);
      /**
       * Creates the instance of the TrackedLocalStorage class
       */
      _defineProperty(this, "_trackedLocalStorage", new _trackedLocalStorage.default());
      /**
       * Array for storing the configured global prefixes to be applied to keys being saved and accessed
       */
      _defineProperty(this, "_globalPrefixes", []);
      /**
       * Shorthand access for the 'length' property of the TrackedLocalStorage
       */
      _initializerDefineProperty(this, "length", _descriptor, this);
      /**
       * Shorthand access for the 'clear' method of the TrackedLocalStorage
       */
      _defineProperty(this, "clear", this._trackedLocalStorage.clear.bind(this._trackedLocalStorage));
      /**
       * Shorthand access for the 'key' method of the TrackedLocalStorage
       */
      _defineProperty(this, "key", this._trackedLocalStorage.key.bind(this._trackedLocalStorage));
    }
    /**
     * Method used for adding or updating a global prefix. Orders the prefixes reverse alphabetically for consistency.
     *
     * @param {string} name
     * @param {string} value
     * @returns {void}
     */
    setGlobalPrefix(name, value) {
      const existingPrefix = this._globalPrefixes.find(_ref => {
        let {
          prefixName
        } = _ref;
        return prefixName === name;
      });
      if (existingPrefix) {
        existingPrefix.prefixValue = value;
      } else {
        this._globalPrefixes = [...this._globalPrefixes, {
          prefixName: name,
          prefixValue: value
        }].sort((a, b) => b.prefixName.localeCompare(a.prefixName));
      }
      // Ensure to trigger a rerender after prefix change so new values are taken into account
      this._trackedLocalStorage.triggerRerender();
    }

    /**
     * First validates the keyName then calls the 'getItem' method of
     * TrackedLocalStorage with the prefixed version of the keyName.
     *
     * @param {string} keyName
     * @param {string[]} skipPrefixes
     * @returns {void}
     */
    getItem(keyName, skipPrefixes) {
      try {
        this._validateKey(keyName);
        return this._trackedLocalStorage.getItem(this._getPrefixedKey(keyName, skipPrefixes));
      } catch (err) {
        console.error(err);
      }
    }

    /**
     * First validates the keyName and value then calls the 'setItem' method of
     * TrackedLocalStorage with the prefixed version of the keyName.
     *
     * @param {string} keyName
     * @param {string} value
     * @param {string[]} skipPrefixes
     * @returns {void}
     */
    setItem(keyName, value, skipPrefixes) {
      try {
        this._validateKey(keyName);
        this._trackedLocalStorage.setItem(this._getPrefixedKey(keyName, skipPrefixes), value);
      } catch (err) {
        console.error(err);
      }
    }

    /**
     * First validates the keyName and then calls the 'removeItem' method of
     * TrackedLocalStorage with the prefixed version of the keyName.
     *
     * @param {string} keyName
     * @param {string[]} skipPrefixes
     * @returns {void}
     */
    removeItem(keyName, skipPrefixes) {
      try {
        this._validateKey(keyName);
        this._trackedLocalStorage.removeItem(this._getPrefixedKey(keyName, skipPrefixes));
      } catch (err) {
        console.error(err);
      }
    }

    /**
     * Applies the prefixes stored in globalPrefixes array to a keyName, providing they
     * are not requested to be skipped
     *
     * @param {string} keyName
     * @param {string[]} skipPrefixes
     * @returns {string}
     */
    _getPrefixedKey(keyName) {
      let skipPrefixes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      return [...this._globalPrefixes.map(_ref2 => {
        let {
          prefixName,
          prefixValue
        } = _ref2;
        return !skipPrefixes.includes(prefixName) && prefixValue;
      }).filter(Boolean), keyName].join('.');
    }

    /**
     * Validates a keyName is present
     *
     * @param {string} keyName
     * @returns {void}
     */
    _validateKey(keyName) {
      if ((0, _utils.isEmpty)(keyName)) {
        throw new Error(`LocalStorage :: Key must not be empty.`);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "length", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});