define("ember-render-helpers/helpers/did-insert", ["exports", "@ember/component/helper", "@ember/debug"], function (_exports, _helper, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * This helper is activated only when it is rendered for the first time
   * (inserted in the DOM). It does not run during or after it is un-rendered
   * (removed from the DOM), or when its arguments are updated.
   */
  class DidInsertHelper extends _helper.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "didRun", false);
    }
    compute(positional, named) {
      const fn = positional[0];
      (true && !(typeof fn === 'function') && (0, _debug.assert)(`\`{{did-insert fn}}\` expects a function as the first parameter. You provided: ${fn}`, typeof fn === 'function'));
      if (this.didRun) return;
      this.didRun = true;
      fn(positional.slice(1), named);
    }
  }
  _exports.default = DidInsertHelper;
});