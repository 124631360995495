define("ember-yeti-table/components/yeti-table/tbody/row/component", ["exports", "@ember-decorators/component", "@ember/array", "@ember/component", "@ember/object", "ember-yeti-table/components/yeti-table/tbody/row/template"], function (_exports, _component, _array, _component2, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  /**
    Renders a `<tr>` element and yields the cell component.
    ```hbs
    <body.row as |row|>
      <row.cell>
        {{person.firstName}} #{{index}}
      </row.cell>
      <row.cell>
        {{person.lastName}}
      </row.cell>
      <row.cell>
        {{person.points}}
      </row.cell>
    </body.row>
    ```
    Remember you can cutomize each `<tr>` class or `@onClick` handler based on the row data
    because you have access to it from the body component.
  
    ```hbs
    <table.body as |body person|>
      <body.row class={{if person.isInvalid "error"}} as |row|>
        <row.cell>
          {{person.firstName}}
        </row.cell>
        <row.cell>
          {{person.lastName}}
        </row.cell>
        <row.cell>
          {{person.points}}
        </row.cell>
      </body.row>
    </table.body>
    ```
  
    @yield {object} row
    @yield {Component} row.cell - the cell component
  */
  let TBodyRow = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec(_class = _dec2(_class = (_class2 = class TBodyRow extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "theme", void 0);
      _defineProperty(this, "columns", void 0);
      /**
       * Adds a click action to the row.
       */
      _defineProperty(this, "onClick", void 0);
      _defineProperty(this, "cells", (0, _array.A)());
    }
    registerCell(cell) {
      let columns = this.get('columns');
      let prop = cell.get('prop');
      if (prop) {
        let column = columns.findBy('prop', prop);
        cell.set('column', column);
      } else {
        let index = this.get('cells.length');
        let column = columns[index];
        cell.set('column', column);
      }
      this.get('cells').addObject(cell);
    }
    unregisterCell(cell) {
      this.get('cells').removeObject(cell);
    }
    handleClick() {
      if (this.get('onClick')) {
        this.get('onClick')(...arguments);
      }
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "handleClick", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleClick"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = _exports.default = TBodyRow;
});