define("ember-svg-jar/inlined/on-demand-user-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_1848_8674)\"><circle cx=\"16.163\" cy=\"16.335\" r=\"14.531\" fill=\"#fff\" stroke=\"#BEA4F3\" stroke-width=\"3\"/><path d=\"M16 23.18c1.38 0 2.63-.39 3.75-1.172 1.146-.807 1.966-1.836 2.46-3.086-.78-.625-1.848-.938-3.202-.938-.755 0-1.563.17-2.422.508-.833.313-1.25.73-1.25 1.25v3.399c.156.026.377.039.664.039zm-1.992-3.438c0-.911.52-1.654 1.562-2.226-.65-.13-1.172-.196-1.562-.196-.703 0-1.498.13-2.383.39-.885.235-1.523.574-1.914 1.017.364.99.924 1.849 1.68 2.578a6.57 6.57 0 002.617 1.562v-3.125zm1.367-7.031a1.793 1.793 0 00-1.367-.586c-.547 0-1.016.195-1.406.586-.391.39-.586.86-.586 1.406 0 .547.195 1.016.586 1.406.39.365.859.547 1.406.547.547 0 1.002-.182 1.367-.547.39-.39.586-.859.586-1.406 0-.547-.195-1.015-.586-1.406zm4.766 1.21a1.543 1.543 0 00-1.133-.468c-.443 0-.82.156-1.133.469-.313.312-.469.69-.469 1.133 0 .442.157.82.469 1.133.313.312.69.468 1.133.468.443 0 .82-.156 1.133-.468.312-.313.468-.69.468-1.133 0-.443-.156-.82-.468-1.133zM16 8.18c2.292 0 4.245.82 5.86 2.46 1.64 1.615 2.46 3.568 2.46 5.86 0 2.292-.82 4.258-2.46 5.898-1.615 1.615-3.568 2.422-5.86 2.422-2.318 0-4.297-.807-5.938-2.422-1.614-1.64-2.421-3.606-2.421-5.898s.807-4.245 2.421-5.86C11.704 9 13.683 8.18 16 8.18z\" fill=\"#74747E\" class=\"cl-fill-current cl-text-primary\"/><path d=\"M14.576 3.184v.444h.444c7.64 0 13.828 6.138 13.828 13.701 0 1.435-.222 2.815-.633 4.11l-.134.425.425.132 2.584.805.42.13.134-.42c.52-1.638.798-3.38.798-5.182C32.442 7.786 24.638.057 15.02.057h-.444V3.184z\" fill=\"#02DC94\" stroke=\"#fff\" stroke-width=\".886\"/></g><defs><clipPath id=\"clip0_1848_8674\"><path fill=\"#fff\" transform=\"translate(0 .5)\" d=\"M0 0h32v32H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "32",
      "height": "33",
      "viewBox": "0 0 32 33",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});