define("ember-data-storefront/-private/utils/get-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.shoeboxize = _exports.serializeObject = _exports.queryCacheKey = _exports.cacheKey = void 0;
  let _serializeParams = function () {
    let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let prefix = arguments.length > 1 ? arguments[1] : undefined;
    const query = Object.keys(params).sort().map(key => {
      const value = params[key];
      if (Array.isArray(params)) {
        key = `${prefix}[]`;
      } else if (params === Object(params)) {
        key = prefix ? `${prefix}[${key}]` : key;
      }
      if (typeof value === 'object' && value !== null) {
        return _serializeParams(value, key);
      } else {
        return `${key}=${encodeURIComponent(value)}`;
      }
    });
    return [].concat.apply([], query).join('&');
  };
  let serializeObject = function (params) {
    return _serializeParams(params);
  };
  _exports.serializeObject = serializeObject;
  let queryCacheKey = function (query) {
    return cacheKey([query.type, query.id, query.params]);
  };
  _exports.queryCacheKey = queryCacheKey;
  let cacheKey = function (args) {
    return args.map(part => typeof part === "object" ? serializeObject(part) : part).filter(part => !!part).join('::');
  };
  _exports.cacheKey = cacheKey;
  let shoeboxize = function (key) {
    return key.replace(/&/g, '--'); // IDGAF
  };
  _exports.shoeboxize = shoeboxize;
});