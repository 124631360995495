define("ember-moment/helpers/moment-calendar", ["exports", "@ember/polyfills", "ember-moment/utils/helper-compute", "ember-moment/helpers/-base"], function (_exports, _polyfills, _helperCompute, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    compute: (0, _helperCompute.default)(function (params) {
      let formatHash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this._super(...arguments);
      if (!params || params && params.length > 3) {
        throw new TypeError('ember-moment: Invalid Number of arguments, at most 3');
      }
      const moment = this.moment;
      const {
        locale,
        timeZone
      } = formatHash;
      const [date, referenceTime, formats] = params;
      const clone = {
        ...formatHash
      };
      delete clone.locale;
      delete clone.timeZone;
      const mergedFormats = (0, _polyfills.assign)(clone, formats);
      return this.morphMoment(moment.moment(date), {
        locale,
        timeZone
      }).calendar(referenceTime, mergedFormats);
    })
  });
});