define("ember-query-params-service/services/query-params", ["exports", "@glimmer/tracking", "@ember/service", "@ember/utils", "qs"], function (_exports, _tracking, _service, _utils, qs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let QueryParamsService = _exports.default = (_class = class QueryParamsService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "current", _descriptor2, this);
      _initializerDefineProperty(this, "byPath", _descriptor3, this);
      this.setupProxies();
      this.updateParams();

      // TODO: drop support for Ember < 3.24 and use @ember/destroyable
      //       to not cause a memory leak in tests
      this.router.on('routeDidChange', () => {
        this.updateParams();
        this.updateURL(this.router.currentRouteName);
      });
    }
    get pathParts() {
      const [path, params] = (this.router.currentURL || '').split('?');
      const absolutePath = path.startsWith('/') ? path : `/${path}`;
      return [absolutePath, params];
    }
    setupProxies() {
      let [path] = this.pathParts;
      this.byPath[path] = this.byPath[path] || {};
      this.current = new Proxy(this.byPath[path], queryParamHandler);
    }
    updateParams() {
      this.setupProxies();
      const [path, params] = this.pathParts;
      const queryParams = params ? qs.parse(params) : {};
      this.setOnPath(path, queryParams);
    }

    /**
     * When we have stored query params for a route
     * throw them on the URL
     *
     */
    updateURL(routeName) {
      const path = this.router.urlFor(routeName);
      const {
        protocol,
        host,
        pathname,
        search,
        hash
      } = window.location;
      const queryParams = this.byPath[path];
      const existing = qs.parse(search.split('?')[1]);
      const query = qs.stringify(sortKeys({
        ...existing,
        ...queryParams
      }));
      const newUrl = `${protocol}//${host}${pathname}${hash}${(0, _utils.isPresent)(query) ? '?' : ''}${query}`;
      window.history.replaceState({
        path: newUrl
      }, '', newUrl);
    }
    setOnPath(path, queryParams) {
      this.byPath[path] = this.byPath[path] || {};
      Object.keys(queryParams || {}).forEach(key => {
        let value = queryParams[key];
        let currentValue = this.byPath[path][key];
        if (currentValue === value) {
          return;
        }
        if (value === undefined) {
          delete this.byPath[path][key];
          return;
        }
        this.byPath[path][key] = value;
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "current", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "byPath", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  })), _class);
  function sortKeys(unordered) {
    return Object.keys(unordered).sort().reduce((obj, key) => {
      obj[key] = unordered[key];
      return obj;
    }, {});
  }
  const queryParamHandler = {
    get(obj, key) {
      for (var _len = arguments.length, rest = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        rest[_key - 2] = arguments[_key];
      }
      return Reflect.get(obj, key, ...rest);
    },
    set(obj, key, value) {
      let {
        protocol,
        host,
        pathname
      } = window.location;
      let query = qs.stringify(sortKeys({
        ...obj,
        [key]: value
      }));
      let newUrl = `${protocol}//${host}${pathname}${(0, _utils.isPresent)(query) ? '?' : ''}${query}`;
      window.history.pushState({
        path: newUrl
      }, '', newUrl);
      for (var _len2 = arguments.length, rest = new Array(_len2 > 3 ? _len2 - 3 : 0), _key2 = 3; _key2 < _len2; _key2++) {
        rest[_key2 - 3] = arguments[_key2];
      }
      return Reflect.set(obj, key, value, ...rest);
    }
  };

  // DO NOT DELETE: this is how TypeScript knows how to look up your services.
});