define("ember-svg-jar/inlined/coach-matching-type-inverted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.032 15.345l2.998-2.999-.469-.484-2.53 2.53-1.186-1.187-.469.469 1.656 1.67zM9.345 11.33c.656-.655 1.442-.983 2.358-.983.916 0 1.697.328 2.342.983.656.646.984 1.427.984 2.343 0 .916-.328 1.702-.984 2.358a3.191 3.191 0 01-2.342.968 3.243 3.243 0 01-2.358-.968 3.243 3.243 0 01-.968-2.358c0-.916.323-1.697.968-2.343z\" fill=\"#5E1DE1\" class=\"cl-fill-current cl-text-primary\"/><path d=\"M13.838 6.805a8.779 8.779 0 00-2.224-.272c-.786 0-1.527.09-2.223.272a9.607 9.607 0 00-1.86.59 2.798 2.798 0 00-1.27 1.089 2.938 2.938 0 00-.454 1.588v1.542h1.829a4.558 4.558 0 018.134 0h1.652v-1.588c0-.544-.152-1.058-.454-1.542a2.799 2.799 0 00-1.27-1.089 9.61 9.61 0 00-1.86-.59zM5.217 6.216c-.363.362-.816.544-1.36.544-.515 0-.969-.182-1.362-.544a1.947 1.947 0 01-.544-1.362c0-.544.181-.998.544-1.36.393-.394.847-.59 1.361-.59.545 0 .998.196 1.361.59.394.362.59.816.59 1.36 0 .515-.196.968-.59 1.362zM4.446 7.803c.182 0 .348.016.5.046a4.447 4.447 0 00-.59 2.223v1.542H0v-1.497c0-.393.106-.756.318-1.089.211-.332.499-.574.862-.726a6.885 6.885 0 011.27-.408c.454-.09.922-.136 1.406-.136.212 0 .409.015.59.045zM20.688 6.216a1.792 1.792 0 01-1.316.544 2.002 2.002 0 01-1.406-.544 1.947 1.947 0 01-.544-1.362c0-.544.181-.998.544-1.36a1.92 1.92 0 011.406-.59c.515 0 .953.196 1.316.59.393.362.59.816.59 1.36 0 .515-.197.968-.59 1.362zM22.911 9.028c.212.333.318.696.318 1.09v1.496h-4.356v-1.542c0-.787-.196-1.528-.59-2.223a2.55 2.55 0 01.5-.046c.181-.03.378-.045.59-.045.483 0 .937.045 1.36.136.454.09.893.227 1.316.408.363.152.65.394.862.726zM13.066.408A2.626 2.626 0 0011.614 0c-.544 0-1.043.136-1.497.408-.423.242-.771.59-1.043 1.044a2.874 2.874 0 00-.363 1.452c0 .544.12 1.043.363 1.497.272.423.62.771 1.043 1.043.454.242.953.363 1.497.363.545 0 1.029-.12 1.452-.363.454-.272.802-.62 1.044-1.043.272-.454.408-.953.408-1.497 0-.545-.136-1.029-.408-1.452A2.48 2.48 0 0013.066.408z\" fill=\"#5E1DE1\" class=\"cl-fill-current cl-text-primary\"/>",
    "attrs": {
      "width": "24",
      "height": "17",
      "viewBox": "0 0 24 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});