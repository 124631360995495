define("ember-yeti-table/-private/helpers/yeti-table-eq", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.yetiTableEq = yetiTableEq;
  /**
   * We need an `eq` helper for the pagination controls page size selector, but:
   * - we can't assume people will have ember-truth-helpers installed
   * - including the entire ember-truth-helpers addon as a dependency brings too much unecessary stuff
   *
   * So we build this private simple helper.
   */
  function yetiTableEq(_ref) {
    let [param1, param2] = _ref;
    return param1 === param2;
  }
  var _default = _exports.default = (0, _helper.helper)(yetiTableEq);
});