define("ember-tracked-local-storage/utils/tracked-local-storage", ["exports", "@glimmer/tracking", "macro-decorators"], function (_exports, _tracking, _macroDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DOES_NOT_EXIST = void 0;
  var _class, _descriptor, _dec, _class3, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const DOES_NOT_EXIST = _exports.DOES_NOT_EXIST = Symbol();

  /**
   * Core TrackedLocalService implementation - designed to be ember agnostic.
   *
   * The purpose of this class is to provide a layer on top of the browser local storage
   * that implements the exact same API, but stores an internal cellsMap object which dynamically
   * creates and holds copies of the values in the browser storage as 'Cell's, with the intention
   * that these cell values can be marked as tracked and trigger template rerenders as necessary.
   */
  let Cell = (_class = class Cell {
    constructor() {
      _initializerDefineProperty(this, "value", _descriptor, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  let TrackedLocalStorage = _exports.default = (_dec = (0, _macroDecorators.reads)('_localStorage.length'), (_class3 = class TrackedLocalStorage {
    constructor() {
      /**
       * Accessing the browser local storage
       */
      _defineProperty(this, "_localStorage", window.localStorage);
      /**
       * Internal map for mirroring the values stored in browser local storage, but with tracked values
       */
      _defineProperty(this, "_cellsMap", {});
      /**
       * Exposes the 'length' property of the browser local storage
       */
      _initializerDefineProperty(this, "length", _descriptor2, this);
      /**
       * Exposes the 'key' method of the browser local storage
       *
       * @param {number} keyNumber
       * @returns {string}
       */
      _defineProperty(this, "key", this._localStorage.key.bind(this._localStorage));
    }
    /**
     * Imitates the 'getItem' method of the browser local storage,
     * but returns the tracked value stored in the cellsMap
     *
     * @param {keyName} string
     * @returns {null|string}
     */
    getItem(keyName) {
      const {
        value
      } = this._getCell(keyName);
      if (value === DOES_NOT_EXIST) {
        return null;
      }
      try {
        return JSON.parse(value);
      } catch (e) {
        return value;
      }
    }

    /**
     * Imitates the 'setItem' method of the browser local storage,
     * but also sets the stringified value in the internal cellsMap
     *
     * @param {keyName} string
     * @returns {void}
     */
    setItem(keyName, value) {
      const serializedValue = JSON.stringify(value);
      this._getCell(keyName).value = serializedValue;
      this._localStorage.setItem(keyName, serializedValue);
    }

    /**
     * Imitates the 'removeItem' method of the browser local storage,
     * but ensures the tracked value in the cellsMap also gets wiped
     *
     * @param {keyName} string
     * @returns {void}
     */
    removeItem(keyName) {
      const storageVal = this.getItem(keyName);
      if (storageVal !== null) {
        // wipe out the cell value if it exists
        this._dirtyCell(this._getCell(keyName));
        this._localStorage.removeItem(keyName);
      }
    }

    /**
     * Imitates the 'clear' method of the browser local storage,
     * but ensures the all tracked value in the cellsMap get wiped
     *
     * @param {keyName} string
     * @returns {void}
     */
    clear() {
      // Dirty every cell
      Object.values(this._cellsMap).forEach(this._dirtyCell);

      // Actually clear
      this._localStorage.clear();
    }

    /**
     * Forces a manual rerender by setting each tracked value to itself
     *
     * @returns {void}
     */
    triggerRerender() {
      Object.values(this._cellsMap).forEach(cell => {
        const x = cell.value;
        cell.value = x;
      });
    }

    /**
     * Gets the requested keyName value from the internal cellsMap.
     * If this doesn't yet exist, creates it with the browser storage value
     * for that keyName, or use the Symbol designed to mark it as not existing
     *
     * @param {keyName} string
     * @returns {Cell}
     */
    _getCell(keyName) {
      let cell = this._cellsMap[keyName];
      if (cell === undefined) {
        const storageVal = this._localStorage.getItem(keyName);
        cell = new Cell();
        cell.value = storageVal !== null && storageVal !== void 0 ? storageVal : DOES_NOT_EXIST;
        this._cellsMap[keyName] = cell;
      }
      return cell;
    }

    /**
     * Manually wipe the content of a cell to trigger a tracking update
     *
     * @param {Cell} cell
     * @returns {void}
     */
    _dirtyCell(cell) {
      // dirty the cell by setting it to a new value
      cell.value = null;
    }
  }, (_descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "length", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class3));
});