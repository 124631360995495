define("ember-svg-jar/inlined/1on1engagement-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect width=\"24\" height=\"24\" rx=\"12\" fill=\"#5E1DE1\" class=\"cl-fill-current cl-text-primary\"/><path d=\"M12.018 18.012a5.762 5.762 0 003.375-1.055c1.03-.727 1.77-1.652 2.214-2.777-.703-.563-1.664-.844-2.882-.844-.68 0-1.407.152-2.18.457-.75.281-1.125.656-1.125 1.125v3.059c.14.023.34.035.598.035zm-1.793-3.094c0-.82.468-1.488 1.406-2.004-.586-.117-1.055-.176-1.406-.176-.633 0-1.348.117-2.145.352-.797.21-1.371.515-1.723.914a6.13 6.13 0 001.512 2.32 5.913 5.913 0 002.356 1.406v-2.812zm1.23-6.328a1.613 1.613 0 00-1.23-.527c-.493 0-.914.175-1.266.527a1.726 1.726 0 00-.527 1.265c0 .493.175.915.527 1.266a1.79 1.79 0 001.266.492c.492 0 .902-.164 1.23-.492.352-.351.527-.773.527-1.266 0-.492-.175-.914-.527-1.265zm4.29 1.09a1.389 1.389 0 00-1.02-.422c-.399 0-.739.14-1.02.422a1.389 1.389 0 00-.422 1.02c0 .398.14.738.422 1.019.281.281.621.422 1.02.422.398 0 .738-.141 1.02-.422.28-.281.421-.621.421-1.02 0-.398-.14-.738-.422-1.02zm-3.727-5.168c2.062 0 3.82.738 5.273 2.215 1.477 1.453 2.215 3.21 2.215 5.273 0 2.063-.738 3.832-2.215 5.309-1.453 1.453-3.21 2.18-5.273 2.18-2.086 0-3.868-.727-5.344-2.18-1.453-1.477-2.18-3.247-2.18-5.309 0-2.063.727-3.82 2.18-5.273C8.15 5.25 9.932 4.512 12.018 4.512z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});